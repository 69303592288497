import { useState, useRef, useEffect } from 'react'
import styles from './styles.module.css'
import emailjs from 'emailjs-com';
import CryptoJS from 'crypto-js';
import clsx from 'clsx';
import { transliterate as tr } from 'transliteration';
import { useSnackbar } from 'notistack'

const PUBLIC_KEY = 'i8525650311';
const PRIVATE_KEY = 'QmX79RX7p2coiMJBOrxvOMsa9AdOElWoJZMPlzQp';
// const PUBLIC_KEY = 'sandbox_i99085240571';
// const PRIVATE_KEY = 'sandbox_LxCwJ3npmJgCK91sqlOic618NmOtW7ENbQRVnhx1';

const ALL_COURSE = [
  "Кар'єрне консультування для IT-спеціалістів",
  "Введення в HR-професію для психологів",
  "Психологічний аспект управління проектами в IT",
  "Психологія користувача та дизайну інтерфейсу",
  "Рекрутинг та відбір персоналу",
  "Проведення інтерв'ю 1+1",
  "Стрес та самозахист в IT-середовищі",
  "Мотивація та збереження персоналу",
  "Робота з вигоранням в організації",
  "Захист проектів"
]

export const PriceSection = ({isMobile}) => {
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null);
  const [selectedCourses, setCourses] = useState([...ALL_COURSE]);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState('');
  const [signature, setSignature] = useState('');
  const [isDataAndSignatureSet, setIsDataAndSignatureSet] = useState(false);
  const [nameAndSurname, setNameAndSurname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }
  
  // Function to generate a unique order ID
  const generateOrderID = () => {
    const timestamp = Date.now().toString();
    const randomString = generateRandomString(6);
    const orderID = timestamp + randomString;
    return orderID;
  }

  const genDscription = () => {
    const courses = selectedCourses.map(el => {
      return ALL_COURSE.indexOf(el) + 1;
    })

    return `
      Payment for the course: ${courses.join(', ')};
      Your mail: ${email};
      Your phone: ${phoneNumber};
      Your full name: ${tr(nameAndSurname)};
    `
  }

  const handleButtonClick = () => {
    const price = selectedCourses.length === 10 ? '4050' : `${selectedCourses.length * 450}`;
    const description = genDscription();
    const inputData = {
      "public_key": PUBLIC_KEY,
      "version":"3",
      "action":"pay",
      "amount": price,
      "currency":"UAH",
      "description": description,
      "order_id": generateOrderID()
    }

    const jsonString = JSON.stringify(inputData);
    const data = btoa(jsonString);
    const signString = `${PRIVATE_KEY}${data}${PRIVATE_KEY}`

    const sha1Hash = CryptoJS.SHA1(signString);
    const signature = CryptoJS.enc.Base64.stringify(sha1Hash);

    setData(data);
    setSignature(signature);
    setIsDataAndSignatureSet(true);
  };

  useEffect(() => {
    if (isDataAndSignatureSet && formRef.current) {
      formRef.current.submit();
    }
  }, [isDataAndSignatureSet]);

  const makeRequest = (token, providerKey, templateKey) => {
    emailjs.init(token);

    const templateParams = {
      message: `User "${nameAndSurname}" has questions. His phone number is ${phoneNumber}. Please call to this phone number`
    };

    if (nameAndSurname !== undefined && phoneNumber !== undefined) {
      emailjs.send(providerKey, templateKey, templateParams)
      .then((response) => {
        enqueueSnackbar(`Дякуємо за звернення, ми скоро з вами звяжемося за цим номером телефону ${phoneNumber}`)
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
    }
  }

  const sendEmails = () => {
    makeRequest(
      'LlEZhiK2jnpa9TKrO',
      'service_2kga4ir',
      'template_ewj4bfq'
    )
    // makeRequest(
    //   process.env.REACT_APP_SECOND_EMAIL_RECEIVER_ACCOUNT,
    //   process.env.REACT_APP_SECOND_EMAIL_RECEIVER_TOKEN,
    //   process.env.REACT_APP_SECOND_EMAIL_RECEIVER_TEMPLATE
    // )
  }

  const handleCheckboxChange = (cours) => (event) => {
    const checked = event.target.checked;

    if (checked) {
      setCourses([...selectedCourses, cours]);
    } else {
      const tmpCourses = selectedCourses.filter(el => el !== cours);
      setCourses(tmpCourses);
    }
  };

  const allowPay = () => {
    const allow = nameAndSurname && email && phoneNumber;
    return allow;
  }

  return (
    <div className={isMobile ? styles.priceSectionMob : styles.priceSection } id='price'>
      <div className={isMobile ? styles.itNavigatorMob : styles.itNavigator }>
        <div className={styles.label}>
          <p className={styles.labelText}>-10% при оплаті всього курсу</p>
        </div>
        <p className={styles.blockHeader}>IT Navigator</p>
        <hr className={styles.blockHr} />
        <p>
          <span className={styles.price1}>450грн</span>
          <span className={styles.price2}>/заняття</span>
        </p>
        <ul className={styles.blockList}>
          <li className={styles.listItem}>Чат для спілкування</li>
          <li className={styles.listItem}>Корисні шаблони та чек-листи</li>
          <li className={styles.listItem}>Словники практичної термінологіі</li>
          <li className={styles.listItem}>Рекомендаціі в оформлені професійного профілю в LI</li>
        </ul>
        <div className={styles.buttonGroup}>
          <button
            onClick={() => setShowModal(true)}
            className={styles.itNavigationButton}
          >
            Купити
          </button>
        </div>
        <p className={styles.itNavigatorNote}>4050грн разом/10 уроків/20 годин</p>
      </div>
      
      <div className={isMobile ? styles.askFormMob : styles.askForm }>
        <p className={isMobile ? styles.askTitleMobile : styles.askTitle}>Сумніваєшся? Ми з радістю проконсультуємо тебе. Залишай свої дані внизу і з тобою звʼяжуться!</p>
        <input
          className={isMobile ? styles.inputMob : styles.input}
          placeholder='Введіть своє імʼя та прізвище'
          value={nameAndSurname}
          onChange={(e) => { setNameAndSurname(e.target.value) }}
          />
        <input
          className={isMobile ? styles.inputMob : styles.input}
          type='number'
          placeholder='Введіть свій номер телефону'
          value={phoneNumber}
          onChange={(e) => { setPhoneNumber(e.target.value) }}
          />
        <button className={isMobile ? styles.askButtonMob : styles.askButton} type='button' onClick={sendEmails}>Надіслати</button>
      </div>
      <form ref={formRef} method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
        <input type="hidden" name="data" value={data} />
        <input type="hidden" name="signature" value={signature} />
      </form>
      {showModal && (
        <>
          <div
            className="backdropBlur"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <div className={clsx('modal')}>
            <h1 className={clsx('paragraph', styles.title)}>
              Виберіть бажані уроки:
            </h1>

            {ALL_COURSE.map((cours, index) => 
              <label key={index}>
                <input
                  type="checkbox"
                  className={styles.checkBox}
                  checked={selectedCourses.includes(cours)}
                  onChange={handleCheckboxChange(cours)}
                />
                {cours}
              </label>
            )}

            <h1 className={clsx('paragraph', styles.title)}>
              Виведіть персональні дані*:
            </h1>
            <input
              className={styles.buyInput}
              placeholder='Введіть своє імʼя та прізвище:'
              value={nameAndSurname}
              onChange={(e) => { setNameAndSurname(e.target.value) }}
            />
            <input
              className={styles.buyInput}
              type='number'
              placeholder='Введіть номер телефону:'
              value={phoneNumber}
              onChange={(e) => { setPhoneNumber(e.target.value) }}
            />
            <input
              className={styles.buyInput}
              placeholder='Введіть свій емейл:'
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            />
            <p className={styles.hint}>*поля обов'язкові для заповнення</p>
            <div className={styles.buttonSection}>
              <button
                onClick={() => handleButtonClick()}
                className={styles.itNavigationButton}
                disabled={!allowPay()}
              >
                Перейти до оплати
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
