import styles from './styles.module.css'
import InstagramLogo from '../../assets/svg/Inatagram Icon.svg'
import LinkedinLogo from '../../assets/svg/Linkedin Icon.svg'
import FacebookLogo from '../../assets/svg/Facebook Icon.svg'
import TiktokLogo from '../../assets/svg/Tok Tok Icon.svg'
import TelegramLogo from '../../assets/svg/Telegram Icon.svg'
import Email from '../../assets/svg/email.svg'


export const Footer = ({isMobile}) => {
  const handleLaunchOutlook = () => {
    const email = 'me@anastasija-ok.com';
    const outlookUrl = `https://outlook.com/?path=/mail/action/compose&to=${encodeURIComponent(email)}`;
    window.open(outlookUrl, '_blank');
  };

  return (
    <div className={styles.container} id='contacts'>
      <div className={styles.footerButtons}>
        <ul className={isMobile ? styles.listMob : styles.list}>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='/' className={styles.link}>Про курс</a></li>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='/' className={styles.link}>Команда</a></li>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='/' className={styles.link}>Часті запитання</a></li>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='/' className={styles.link}>Вартість</a></li>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='#offerta' className={styles.link}>Оферта</a></li>
          <li className={isMobile ? styles.itemMob : styles.item}><a href='#terms' className={styles.link}>Політика конфіденційності</a></li>
        </ul>
      </div>
      <div className={styles.socialNetworks}>
        <ul className={styles.socialList}>
          {!isMobile && (
            <>
              <li className={styles.network}><a className={styles.phone} href='https://t.me/+S3aknSuC23FlMDUy' target='blank'><img src={TelegramLogo} className={styles.mr}  alt='Logo'/> +380 98 802 56 01</a></li>
              <li className={styles.network}><button className={styles.phone} onClick={() => handleLaunchOutlook()} target='blank'><img src={Email} className={styles.mr}  alt='Logo'/> me@anastasija-ok.com</button></li>
            </>
          )}  
          <li className={styles.network}><a href='https://instagram.com/lt_navigator?igshid=MzRlODBiNWFlZA==' target='blank'><img src={InstagramLogo} alt='Logo'/></a></li>
          <li className={styles.network}><a href='https://www.linkedin.com/in/anastasija-lenchinskaja' target='blank'><img src={LinkedinLogo}  alt='Logo'/></a></li>
          <li className={styles.network}><a href='https://www.facebook.com/ITNavigatorIT' target='blank'><img src={FacebookLogo}  alt='Logo'/></a></li>
          <li className={styles.network}><a href='https://www.tiktok.com/@it_navigator?_t=8dMQQ7Pi1Xq&_r=1' target='blank'><img src={TiktokLogo}    alt='Logo'/></a></li>
        </ul>
      </div>
      {isMobile && (
        <div className={styles.socialNetworks}>
          <ul className={isMobile ? styles.listMob : styles.list}>
            <li className={styles.network}><a className={styles.phone} href='https://t.me/+S3aknSuC23FlMDUy' target='blank'><img src={TelegramLogo} className={styles.mr}  alt='Logo'/> +380 98 802 56 01</a></li>
            <li className={styles.network}><button className={styles.phone} onClick={() => handleLaunchOutlook()} target='blank'><img src={Email} className={styles.mr}  alt='Logo'/> me@anastasija-ok.com</button></li>
          </ul>
        </div>
      )}
      <hr />
      <p>All rights reserved</p>
    </div>
  )
}
