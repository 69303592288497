import styles from './styles.module.css';
import Smile from '../../assets/smile.png';
import Main from '../../assets/svg/main.svg'

export const InfoSection = ({isMobile}) => {

  const redirectToForm = () => {
    window.location.href = 'https://forms.gle/HZdToGPzx5VJAsqq9';
  }

  return (
    <div className={styles.main}>
      <div className={styles.imageSection}>
        <img src={Main} className={isMobile ? styles.mainImageMob : styles.mainImage} alt='main' />
      </div>
      <div className={isMobile ? styles.containerMob : styles.container}>
        <p className={isMobile ? styles.titleMob : styles.title}>Курс, що змінить твою думку про ІТ сферу на 180 градусів</p>
        <p className={isMobile ? styles.subtitleMob : styles.subtitle}>Розроблено спеціально для <span className={styles.blue}>психологів</span>, <span className={styles.blue}>філологів</span>, <span className={styles.blue}>соціологів</span>, які хочуть відкрити для себе нові горизонти в галузі IT.</p>
      </div>
      <div className={isMobile ? styles.startCourseBlockMob : styles.startCourseBlock}>
        <p className={isMobile ? styles.startCourceTextMob : styles.startCourceText}>Початок курсу <b className={styles.marginX}>29.07</b> <img className={styles.smile} src={Smile} alt='Smile' /></p>
      </div>
      <div className={isMobile ? styles.bottomContainerMob : styles.bottomContainer}>
        <ul className={isMobile ? styles.listMob : styles.list}>
          <li className={styles.item + ' ' + styles.redMarker}>Основні поняття та терміни в IT-галузі</li>
          <li className={styles.item + ' ' + styles.blueMarker}>Пошук роботи та підготовка резюме</li>
          <li className={styles.item + ' ' + styles.greenMarker}>Ефективне спілкування в IT-середовищі</li>
          <li className={styles.item + ' ' + styles.yellowMarker}>Практичні уроки</li>
        </ul>
        <div className={styles.groupButtons}>
        <a href='#contacts' className={isMobile ? styles.connectButtonMob : styles.connectButton}>Приєднатися</a>
        <button
          className={isMobile ? styles.checkFreeButtonMob : styles.checkFreeButton}
          onClick={()=> redirectToForm()}
        >
          Cпробувати безкоштовно
        </button>
        </div>
      </div>
    </div>
  )
}
