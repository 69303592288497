export const Terms = ({setShowModal}) => {
  return (
    <>
      <div
        className="backdropBlur"
        onClick={() => {
          setShowModal('');
          window.location.hash = 'contacts';
        }}
      />
      <div className='modal'>
        <h3>Політика конфіденційності ФОП Анастасія Ленчинська</h3>
        <p className='paragraph'>Дата опублікування: 02.07.2023</p>
        <p className='paragraph'>
          Ця Політика конфіденційності (далі – «Політика») визначає порядок обробки персональних даних Користувачів («Користувач(і)», а також «ви») вебсайту https://anastasija-ok.com (далі – «https://anastasija-ok.com», «ми»), розміщеним за адресою https://anastasija-ok.com, а також додатків https://anastasija-ok.com та інших проєктів (далі – «Вебсайт»).
        </p>
        <p className='paragraph'>Зокрема, Політика визначає: </p>
        <p className='paragraph'>
          * які саме персональні дані обробляє https://anastasija-ok.com та способи обробки;
          <br />
          * хто здійснює обробку персональних даних; 
          <br />
          * які цілі такої обробки; 
          <br />
          * які права має Користувач стосовно таких даних; 
          <br />
          * чи передаються дані третім особам та кому; 
          <br />
          * яких заходів вживає https://anastasija-ok.com для захисту персональних даних та строк * зберігання даних; 
          <br />
          * а також інші деталі обробки персональних даних. 
        </p>
        <p className='paragraph'>
          Якщо у вас виникнуть будь-які запитання щодо цієї Політики, обробки ваших персональних даних сервісом https://anastasija-ok.com чи реалізації ваших прав як суб'єкта персональних даних, надішліть нам листа на цю електронну пошту: me@anastasija-ok.com</p>
        <h4 className='paragraph'>1. Що таке персональні дані? </h4>
        <p className='paragraph'>
          Персональними даними є відомості чи сукупність відомостей про вас, які дають можливість для особи, яка збирає та обробляє таку інформацію, ідентифікувати вас. Персональними даними, зокрема, є ваше ім'я, ідентифікаційний номер, дані про місцезнаходження чи інша інформація про вас. 
          <br/>
          Обробкою персональних даних вважається будь-яка дія або сукупність дій з вашими персональними даними, зокрема, збирання, реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, використання і поширення, знеособлення, знищення персональних даних. 
        </p>
        <h4 className='paragraph'>
          2. Які персональні дані обробляє https://anastasija-ok.com та чому? 
        </h4>
        <p className='paragraph'>
          Категорія Користувачів Обсяг персональних даних та мета їхньої обробки Зареєстровані користувачі Щоб надати вам можливість створити власний Обліковий запис та користуватись послугами https://anastasija-ok.com через нього, ми просимо надати нам інформацію про ваше повне ім'я та електронну пошту.
        </p>
        <p className='paragraph'>
          Ми використовуємо адресу вашої електронної пошти для комунікації з вами, щоб: (1) надсилати підтвердження про замовлення та/або оплату; (2) надсилати комерційні пропозиції, що можуть вас зацікавити; (3) надавати технічну підтримку та надсилати пароль, якщо ви забули його. Усі користувачі Для того, щоб надати вам можливість скористатись Послугами https://anastasija-ok.com без реєстрації або з нею, ми збираємо та обробляємо інформацію про: 
        </p>
      
        <p className='paragraph'>
          * повне ім'я; 
          <br/>
          * електронну пошту;
          <br/>
          * контактний номер телефону; 
        </p>

        <p className='paragraph'>
          Без цієї інформації ми не зможемо надати весь перелік Послуг, передбачений умовами Публічної оферти.
        </p>
        <p className='paragraph'>
          Ми використовуємо адресу вашої електронної пошти для комунікації з вами, щоб: (1) надсилати підтвердження про оплату Послуг; (2) надсилати комерційні пропозиції, що можуть вас зацікавити; (3) надавати технічну підтримку.
        </p>
        <p className='paragraph'>
          Як незареєстрований користувач ви можете звернутися до https://anastasija-ok.com з будь-яких питань через форму на Вебсайті. У ній ви може надати нам своє ім'я, електронну пошту та інші дані, якими ви можете поділитись на власний розсуд. Ми використовуватимемо ці дані, щоб надати вам відповідь на ваші звернення.
        </p>
        <p className='paragraph'>
          Ви можете підписатися на наші новини та акції, надавши вашу електронну пошту у відповідному полі Вебсайту.
        </p>
        <p className='paragraph'>
          Якщо ми направлятимемо вам комерційні повідомлення на вашу електронну пошту, у будь-якому разі, ми надаватимемо вам безкоштовне право відмовитися від таких комерційних повідомлень.
          Для того, щоб покращити та оптимізувати роботу нашого Вебсайту, а також для того, щоб зрозуміти ваші потреби та надавати послуги та рекламу, релевантну для вас, ми можемо збирати ваші користувацькі дані за допомогою файлів cookies. Користувацькі дані відображають вашу поведінку на нашому Вебсайті. Ми також можемо збирати параметри IP-адреси, інформацію про джерело трафіку, пристрій та операційну систему, роздільну здатність екрану, тривалість сесії, ваше місцезнаходження. 
        </p>
        <h4 className='paragraph'>
          3. Підстави для обробки персональних даних 
        </h4>
        <p className='paragraph'>
          У випадках, коли персональні дані обробляються винятково з метою надання вам послуг на ваш індивідуальний запит, https://anastasija-ok.com здійснюватиме обробку персональних даних на підставі Договору, укладеного шляхом прийняття вами Публічної оферти, відповідно до частин 5 та 6 статті 11 Закону України «Про електронну комерцію» та пункту 3 частини 1 статті 11 Закону України «Про захист персональних даних». 
        </p>
        <p className='paragraph'>
          Щодо інших видів обробки, то при реєстрації в https://anastasija-ok.com або оформленні замовлення без реєстрації, ви надаєте згоду на обробку ваших персональних даних відповідно до частини 3 статті 14 Закону України «Про електронну комерцію» та відповідно до пункту 1 частини 1 статті 11 Закону України «Про захист персональних даних». 
        </p>
        <p className='paragraph'>
          В усіх інших випадках ви добровільно поширюєте свої персональні дані шляхом заповнення форм, про які ми писали вище. 
        </p>
        <h4 className='paragraph'>
          4. Хто має доступ до персональних даних? 
        </h4>
        <p className='paragraph'>
          Щоб надавати вам якісні послуги, ми наймаємо працівників та залучаємо третіх осіб, які можуть отримувати доступ до ваших персональних даних. Третіми особами, що можуть отримувати доступ до ваших персональних даних, є: 
        </p>
        <p className='paragraph'>
          * Незалежні підрядники, у тому числі фізичні особи-підприємці, яких ми залучаємо до надання послуг https://anastasija-ok.com, зокрема, для розробки та технічної підтримки Вебсайту. 
          <br />
          * Партнери https://anastasija-ok.com, що пропонують Користувачам отримати послуги, інформація про які розміщується на Вебсайті (спеціальні пропозиції). Із переліком партнерів можна ознайомитися на Вебсайті. 
          <br />
          * Компанія Google LLC та афілійовані з нею юридичні особи («Google»), яка надає нам послуги сервісу Google Analytics. За допомогою цього сервісу ми можемо збирати та аналізувати дані про те, як використовується наш Вебсайт, щоб покращити його роботу та зробити наші послуги більш зручними та корисними для вас. 
          <br />
          *  Компанія Facebook Inc. та афілійовані з нею юридичні особи, які надають нам послуги сервісу Facebook Pixel, Facebook events та Workplace. Ці сервіси надають нам можливість налаштовувати та здійснювати аналітику реклами та організовувати події у соціальній мережі Facebook, завдяки цьому сервісу у нас є можливість організувати робочий простір (Workspace) для Користувачів 
          <br />
          * Компанія Zoom Video Communications, Inc. та афілійовані з нею юридичні особи, які надають послуги відеозв'язку. За допомогою цього сервісу ми можемо проводити відео конференції та онлайн-зустрічі. 
          <br />
          * Крім цього, ми використовуємо послуги платіжного сервісу LiqPay.  Цей сервіс дозволяє нам проводити оплату за Послуги. Водночас https://anastasija-ok.com не має доступу до фінансової інформації своїх Користувачів, обробка такої інформації здійснюється лише у рамках сервісу LiqPay.
        </p>
        <p className='paragraph'>
          Будь ласка, ознайомтесь з їх політикою конфіденційності кожної з вказаних третіх осіб для того, щоб дізнатись, як саме вони здійснюють обробку та захист ваших персональних даних. 
        </p>
        <h4 className='paragraph'>
          5. Які права має Користувач? 
        </h4>
        <p className='paragraph'>
          Будь-яка фізична особа має визначені законом права щодо своїх персональних даних, і https://anastasija-ok.com поважає їх. Як суб'єкт персональних даних, ви маєте право: 
        </p>
        <p className='paragraph'>
          * знати про джерела збирання, місцезнаходження своїх персональних даних, мету їхньої обробки, місцезнаходження або місце проживання володільця чи осіб, які від імені володільця обробляють персональні дані. Ви також можете дати доручення щодо отримання цієї інформації іншим особам; 
          <br />
          * отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються такі персональні дані; 
          <br />
          * на доступ до своїх персональних даних; 
          <br />
          * надіслати https://anastasija-ok.com запит на отримання інформації про те, чи обробляються ваші персональні дані, а також на доступ до таких персональних даних, що обробляються, та отримати відповідь протягом 30 календарних днів з дня, коли ми отримаємо ваш запит;
          <br />
          * пред'являти https://anastasija-ok.com вмотивовану вимогу із запереченням проти обробки своїх персональних даних; 
          <br />
          * пред'являти https://anastasija-ok.com вмотивовану вимогу щодо зміни або знищення своїх персональних даних, якщо ці дані обробляються незаконно чи є недостовірними; 
          <br />
          * на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також
          на захист від надання відомостей, що є недостовірними чи ганьблять вашу честь, гідність та ділову репутацію; 
          <br />
          * звертатися зі скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду; 
          <br />
          * застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних; 
          <br />
          * вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди; 
          <br />
          * відкликати згоду на обробку персональних даних; 
          <br />
          * знати механізм автоматичної обробки персональних даних; 
          <br />
          * мати захист від автоматизованого рішення, яке має для вас правові наслідки. 
        </p>
        <p className='paragraph'>
          Щоб реалізувати перелічені вище права, ви можете надіслати запит на нашу електронну пошту: me@anastasija-ok.cim. Ми старатимемось відповісти якнайшвидше. Ми також повідомимо вам, якщо ваш запит вимагає багато часу на обробку. https://anastasija-ok.com не буде брати оплату за ваш запит. Задля захисту ваших персональних даних ми можемо вимагати від вас пройти ідентифікацію перед тим, як розкривати дані, щодо яких надійшов запит. 
        </p>
        <h4 className='paragraph'>
          6. Безпека персональних даних 
        </h4>
        <p className='paragraph'>
          Ми вживаємо належних заходів безпеки, щоб захистити ваші персональні дані від випадкової втрати або знищення, від незаконної обробки, незаконного знищення чи доступу до них. Зокрема, ми створюємо резервні копії ваших даних, використовуємо надійні паролі та надаємо доступ до персональних даних обмеженому колу осіб та лише у випадку необхідності (наприклад, коли це потрібно для надання послуг, передбачених Публічною офертою https://anastasija-ok.com, виконання умов цієї Політики, для захисту законних інтересів https://anastasija-ok.com, Користувачів чи третіх осіб тощо). 
        </p>
        <p className='paragraph'>
          Зареєстровані Користувачі можуть у будь-який момент відредагувати дані у своєму Обліковому записі. У разі таких змін https://anastasija-ok.com не зберігатиме старі дані, і вони будуть остаточно видалені з технічних записів роботи Вебсайту протягом трьох місяців. 
        </p>
        <p className='paragraph'>
          Інші персональні дані, які ми збираємо, ми зберігаємо виключно протягом строку виконання мети, для якої ви поширили ці дані з нами. Якщо ви бажаєте, щоб ми перестали обробляти ці дані, будь ласка, зверніться до нас із відповідним запитом. 
        </p>
        <p className='paragraph'>
          Якщо ви бажаєте видалити свій Обліковий запис, то можете подати індивідуальний запит на електронну пошту me@anastasija-ok.com 
        </p>
        <h4 className='paragraph'>
          7. Файли cookies 
        </h4>
        <p className='paragraph'>
          Cookies – це невеликі шматочки коду, що залишаються у вашому пристрої після того, як ви відвідали якийсь вебсайт. Ці шматочки дають можливість серверу надавати вам інформацію, індивідуалізовану під ваші потреби, коли ви будете використовувати певну платформу чи вебсайт наступного разу. 
        </p>
        <p className='paragraph'>
          Надання вами згоди https://anastasija-ok.com на використання файлів cookies робить наш Вебсайт більш ефективним, швидким та безпечним, а також покращує його функціонування. 
        </p>
        <p className='paragraph'>
          У будь-якому випадку, ви можете заблокувати, видалити, чи вимкнути зберігання файлів cookies на вашому пристрої, якщо ваш браузер дозволяє вам це зробити. Ці посилання можуть бути корисними для того, щоб ви могли вибрати найкращу опцію вашого браузера: 
        </p>
        <p className='paragraph'>
          * Для користувачів <a href='https://support.microsoft.com/uk-ua/windows/%D0%B2%D0%B8%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%BD%D1%8F-%D1%84%D0%B0%D0%B9%D0%BB%D1%96%D0%B2-cookie-%D1%82%D0%B0-%D0%BA%D0%B5%D1%80%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F-%D0%BD%D0%B8%D0%BC%D0%B8-168dab11-0753-043d-7c16-ede5947fc64d' className='link'>Internet Explorer</a>
          <br/>
          * Для користувачів <a href='https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666' className='link'>Chrome</a> Для користувачів <a href='https://support.apple.com/uk-ua/guide/safari/sfri11471/mac' className='link'>Safari web</a> та <a href='https://support.apple.com/uk-ua/HT201265' className='link'>iOS</a>. 
        </p>
        <h4 className='paragraph'>
          8. Зміни до цієї Політики конфіденційності 
        </h4>
        <p className='paragraph'>
          Ми можемо час від часу змінювати нашу Політику конфіденційності, оновлюючи дату опублікування у верхній частині тексту цієї Політики. Подальше використання Вебсайту підтверджує вашу згоду з нашою Політикою. Якщо ви не згодні з умовами Політики, будь ласка, припиніть використання Вебсайту. У будь-якому разі, якщо зміни до Політики є важливими для захисту вашого права на приватність, ми додатково запитаємо вашої згоди щодо умов оновленої версії Політики. 
        </p>
        <p className='paragraph'>
          Мета обробки, а також склад персональних даних та процедури їхньої обробки визначаються володільцем персональних даних. 
        </p>
        <p className='paragraph'>
          Володільцем даних, які ми збираємо, є https://anastasija-ok.com. 
        </p>
        <h4 className='paragraph'>
          Реквізити Виконавця
        </h4>
        <p className='paragraph'>
          https://anastasija-ok.com
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія Анатоліівна
        </p>
        <p className='paragraph'>
          ІПН: 2885301803
        </p>
        <p className='paragraph'>
          Реєстр.№: 25960170000001634 від 10.09.2002 
        </p>
        <p className='paragraph'>
          IBAN: UA413052990000026000040702800
        </p>
        <p className='paragraph'>
          Платник єдиного податку 3 група, ставка 5%. Не є платником ПДВ  
        </p>
        <p className='paragraph'>
          Ел. пошта: me@anastasija-ok.com 
        </p>
        <p className='paragraph'>
          Телефон: +38 097 921 04 02
        </p>
      </div>
    </>
  )
}
