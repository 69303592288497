import styles from './styles.module.css';
import clsx from 'clsx'

export const ProgramSection = ({isMobile}) => {
  return (
    <div className={isMobile ? styles.containerMob : styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Програма</p>
        <p className={styles.subtitle}>10 уроків по 2 години</p>
      </div>
      <div className={isMobile ? styles.lessonsBlockMob : styles.lessonsBlock}>
        <div className={isMobile ? styles.elementMob : styles.element}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Кар'єрне консультування для IT-спеціалістів</p>
        </div>
        <div className={isMobile ? styles.elementMob : styles.element}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Введення в HR-професію для психологів</p>
        </div>
        <div className={clsx(isMobile ? styles.elementMob : styles.element, styles.red)}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Психологічний аспект управління проектами в IT</p>
        </div>
        <div className={isMobile ? styles.elementMob : styles.element}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Психологія користувача та дизайну інтерфейсу</p>
        </div>
      </div>

      <div className={isMobile ? styles.lessonsBlockMob : styles.lessonsBlock}>
        <div className={clsx(isMobile ? styles.elementMob : styles.element, styles.white)}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Рекрутинг та відбір персоналу</p>
        </div>
        <div className={isMobile ? styles.elementMob : styles.element}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Проведення інтерв'ю 1+1</p>
        </div>
        <div className={isMobile ? styles.elementMob : styles.element}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Стрес та самозахист в IT-середовищі</p>
        </div>
        <div className={clsx(isMobile ? styles.elementMob : styles.element, styles.white, styles.purpleBorder)}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Мотивація та збереження персоналу</p>
        </div>
      </div>
      <div className={isMobile ? styles.lessonsBlockMob : styles.lessonsBlock}>
        <div className={clsx(isMobile ? styles.elementMob : styles.element, styles.purpleBackground)}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Робота з вигоранням в організації</p>
        </div>
        <div className={clsx(isMobile ? styles.elementMob : styles.element, styles.yellow)}>
          <p className={isMobile ? styles.elementContentMob : styles.elementContent}>Захист проектів</p>
        </div>
      </div>
    </div>
  )
}
