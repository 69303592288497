export const Offerta = ({setShowModal}) => {
  return (
    <>
      <div
        className="backdropBlur"
        onClick={() => {
          setShowModal('');
          window.location.hash = 'contacts';
        }}
      />
      <div className='modal'>
        <h3>
          Договір публічної оферти
          ФОП Анастасія Ленчинська
        </h3>
        <p className='paragraph'>Дата опублікування: 02.07.2023</p>
        <p className='paragraph'>
          Ця Публічна оферта (також «Умови використання» або «Умови») містить умови юридично обов'язкового договору приєднання («Договір») між ФОП Ленчинською Анастасією Анатоліівною  та Користувачем (далі – «Користувач(і)», а також «ви»), які при згадуванні разом у відповідному контексті зазначаються як «Сторони».
        </p>
        <p className='paragraph'>
          Порядок приймання умов Публічної оферти та укладення Договору між вами та ФОП Ленчинською Анастасією визначений нижче. Будь ласка, уважно прочитайте ці Умови перед використанням вебсайту https://anastasija-ok.com, його додатків та пов'язаних проєктів (далі – «Вебсайт»). Використання Вебсайту означає прийняття умов Публічної оферти.
        </p>
        <p className='paragraph'>
          Якщо ви вважаєте, що будь-яка інформація чи матеріали на Вебсайті порушують ваші права чи застосовне законодавство, або якщо у вас виникнуть питання щодо використання Вебсайту, чи у вас є якісь ідеї щодо того, як можна покращити наш сервіс, будь ласка, надішліть нам листа на електронну пошту me@anastasija-ok.com
        </p>
        <h4 className='paragraph'>
          1. Визначення термінів
        </h4>
        <p className='paragraph'>
          «Ресурси» — відомості та матеріали, що доступні Користувачам, зокрема: інформація про онлайн та офлайн події та Курси, відео, презентації, бібліотека шаблонів та інших корисних матеріалів, розміщених на Вебсайті.
        </p>
        <p className='paragraph'>
          «Послуги» — послуги, які надаються ФОП Ленчинською Анастасією  Користувачу, зокрема: проведення воркшопів, Q&A сесій; проведення Курсів; надання доступу до інших інтерактивних функцій Вебсайту.
        </p>
        <p className='paragraph'>
          «Техпідтримка» — надання в режимі онлайн роз'яснень щодо питань, пов'язаних з використання Вебсайту. Техпідтримка надається з урахуванням кількості Користувачів, інтенсивності та складності запитів. Техпідтримка не включає в себе інсталяцію програмного забезпечення на комп'ютер або інше обладнання Користувача та інші дії.
        </p>
        <p className='paragraph'>
          «Курси» - програми навчання у сфері IT можуть бути платними та безоплатними.
        </p>
        <h4 className='paragraph'>
          2. Порядок укладення Договору між Користувачем та ФОП Ленчинською Анастасією
        </h4>
        <p className='paragraph'>
          Розміщення Публічної оферти на Вебсайті є пропозицією укласти електронний договір про надання Послуг між ФОП Ленчинською Анастасією та Користувачем. Умови електронного договору про надання послуг між ФОП Ленчинською Анастасією та Користувачем викладені в цій Публічній оферті.
        </p>
        <p className='paragraph'>
          Користувач вважається таким, що прийняв Публічну оферту, за умови виконання ним сукупності наступних дій: якщо він ідентифікувався (зареєструвався) на Вебсайті шляхом заповнення електронної форми замовлення Послуг; якщо він здійснив платіж на умовах, визначених ФОП Ленчинською Анастасією.
        </p>
        <p className='paragraph'>
          Публічна оферта приймається Користувачем в редакції, розміщеній на Вебсайті на момент її прийняття.
        </p>
        <p className='paragraph'>
          Прийняття Публічної оферти означає, що Користувач погоджується з її умовами та вона становить договір між Користувачем та ФОП Ленчинською Анастасією щодо надання Послуг останнім та щодо використання Вебсайту користувачем.
        </p>
        <h4 className='paragraph'>
          3. Послуги ФОП Ленчинською Анастасією
        </h4>
        <p className='paragraph'>
          За цією Публічною офертою ФОП Ленчинська Анастасія зобов'язується надати Послуги.
        </p>
        <p className='paragraph'>
          Обсяг доступних вам Послуг, залежить від умов придбаної вами послуги, курсів. Вартість Курсів визначається на Вебсайті. Деякі варіанти Послуг надаються безкоштовно, інші варіанти Послуг можуть надаватись тільки після повної оплати.
        </p>
        <p className='paragraph'>
          ФОП Ленчинською Анастасією пропонує наступні варіанти послуг:
        </p>
        <p className='paragraph'>
          * Безкоштовні вебінари  — можливість отримувати доступ до обмеженої кількості визначених ФОП Ленчинською Анастасією навчальних проектів протягом певного строку без оплати. ФОП Ленчинською Анастасією залишає за собою право змінити умови надання послуг, а також змінювати умови надання в будь-який час без попереднього повідомлення і без будь-якої відповідальності.
          <br/>
          * Платні курси — можливість отримати доступ до навчальних курсів  у строк та на умовах, визначених ФОП Ленчинською Анастасією на Вебсайті.
        </p>
        <p className='paragraph'>
          Послуги вважаються повністю наданими ФОП Ленчинською Анастасією в рамках окремо визначених дат проведення навчальних курсів зазначених на Вебсайті. Якщо для отримання доступу до курсів передбачена поетапна оплата, то невнесення обумовленого платежу Користувачем означає його відмову від цього Договору. В такому випадку послуги ФОП Ленчинська Анастасія за цією Підпискою вважаються повністю виконаними в момент закінчення періоду сплаченої частини Підписки.
        </p>
        <h4 className='paragraph'>
          4. Права та обов'язки Сторін
        </h4>
        <p className='paragraph'>
          Користувач зобов'язаний:
        </p>
        <p className='paragraph'>
          * До моменту укладення Договору ознайомитись з умовами Публічної оферти та умовами надання Послуг, розміщеними на Вебсайті;
          <br/>
          * Дотримуватися умов цієї Публічної оферти;
          <br/>
          * У разі прийняття умов Публічної оферти – надати достовірну інформацію про себе шляхом заповнення необхідних розділів Вебсайту;
          <br/>
          * Здійснювати сплату Послуг вчасно та у повному обсязі відповідно до положень  Публічної оферти;
          <br/>
          * Використовувати Ресурси тільки в особистих навчальних цілях. Використання в комерційних цілях та передача третім особам заборонена і може тягнути за собою відповідальність згідно з законодавством України.
        </p>
        <p className='paragraph'>
          Користувач має право:
        </p>
        <p className='paragraph'>
          * Протягом строку, зазначеного в п. 4 цих Умов, отримувати від ФОП Ленчинською Анастасією Послуги, вартість яких була повністю сплачена.
          <br/>
          * Припинити отримання Послуг в порядку, встановленому цією Публічною офертою.
        </p>
        <p className='paragraph'>
          ФОП Ленчинською Анастасією зобов'язується:
        </p>
        <p className='paragraph'>
          * Надавати Послуги в повному обсязі та належним чином протягом строку та на умовах, визначених в Публічній оферті;
          <br/>
          * Докладати всіх можливих розумних зусиль для забезпечення функціонування Вебсайту.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія має право:
        </p>
        <p className='paragraph'>
          * Змінювати Вебсайт, при цьому, Користувач повинен самостійно ознайомитися з оновленим функціоналом та правилами роботи Вебсайту;
          <br/>
          * Визначати обсяг обмеженого доступу Користувача до Ресурсів;
          <br/>
          * Припинити надання Послуг в порядку, встановленому цією Публічною офертою.
        </p>
        <h4 className='paragraph'>
          5. Ціна, порядок оплати та відмови від Послуг
        </h4>
        <p className='paragraph'>
          Ціна Послуг складає, в залежності від обраного варіанту курсів, які описані в окремому розділі про вартість курсів. ФОП Ленчинська Анастасія має право в односторонньому порядку змінювати ціни на Послуги, шляхом попередньої публікації таких змін на Вебсайті.
        </p>
        <p className='paragraph'>
          Зазначена ціна Послуг є чинною на момент прийняття цієї Публічної оферти та не включає податки, мита, збори, комісію банку, у тому числі додаткові комісії банку при сплаті частинами, або інші обов'язкові платежі (далі – «супутні платежі»). Всі супутні платежі сплачуються Користувачем самостійно, якщо інше не встановлено Договором з ФОП Ленчинською Анастасією.
        </p>
        <p className='paragraph'>
          Послуги надаються за умови попередньої сплати повної Ціни Послуг. Оплата здійснюється Користувачем шляхом перерахування грошових коштів на платіжні реквізити ФОП Ленчинською Анастасією за допомогою систем еквайрингу сервісу лікпей, підключеного до Вебсайту.
        </p>
        <p className='paragraph'>
          У випадку, коли оплата Послуг відбувається в іноземній валюті, грошові кошти мають бути перераховані на платіжні реквізити ФОП Ленчинською Анастасією в розмірі, еквівалентному повній Ціні Послуг та розрахованому за курсом відповідної іноземної валюти на міжбанку, актуальним на дату здійснення платежу. Актуальні міжбанківські курси розміщені на вебсайті <a href='https://minfin.com.ua/ua/currency/mb/' className='link'>Мінфін</a>. 
        </p>
        <p className='paragraph'>
          Користувач може відмовитись від подальшого отримання Послуг після відкриття електронного кабінету з Ресурсами, якщо вимога про відмову від отримання Послуг надійшла протягом 24 годин з моменту сплати Ціни Послуг.
        </p>
        <p className='paragraph'>
          У випадку відмови від Послуг на умовах, описаних в цьому пункті, Користувачу в повному обсязі повертаються сплачені ним грошові кошти. Повернення вартості Послуг здійснюється з урахуванням знижок, отриманих Користувачем.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія має право припинити надання Послуг в односторонньому порядку у будь-який момент у випадку порушення Користувачем Умов використання, зокрема через нецензурну лайку, погрози, принизливі висловлювання на адресу ФОП Ленчинську Анастасію чи інших осіб, та інші форми протиправної поведінки. Про припинення надання Послуг ФОП Ленчинська Анастасія зобов'язується попередньо повідомити Користувача. У такому випадку ФОП Ленчинською Анастасією має право не повертати Користувачу наперед сплачену вартість Послуг.
        </p>
        <h4 className='paragraph'>
          6. Права інтелектуальної власності
        </h4>
        <p className='paragraph'>
          Вебсайт та його компоненти, у тому числі програмний код, логотипи та елементи дизайну, торговельні марки, тексти, графіка, дані, статті, інформація, фото, зображення, ілюстрації, відео тощо, належать ФОП Ленчинській Анастасії й охороняються законодавством у сфері інтелектуальної власності.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія надає Користувачам невиключну відкличну безоплатну ліцензію без права передання третім особам на доступ та використання Вебсайту у межах функціоналу, доступного в онлайн-режимі, що уможливлює надання Послуг, визначених цими Умовами. Така ліцензія діє на території всього світу протягом строку чинності цих Умов.
        </p>
        <p className='paragraph'>
          Використання в комерційних цілях будь-яких матеріалів або компонентів Вебсайту в інший спосіб, ніж це передбачено цими Умовами, без письмового дозволу ФОП Ленчинської Анастасії суворо заборонене.
        </p>
        <h4 className='paragraph'>
          7. Персональні дані
        </h4>
        <p className='paragraph'>
          При заповненні форми, розміщеної на Вебсайті ви надаєте нам дозвіл на обробку і зберігання ваших персональних даних, вказаних під час реєстрації.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія має право зберігати та обробляти ваші персональні дані виключно для цілей надання Послуг.
        </p>
        <p className='paragraph'>
          ФОП Ленчинською Анастасією здійснює обробку персональних даних на умовах, визначених Політикою конфіденційності.
        </p>
        <h4 className='paragraph'>
          8. Використання Вебсайту
        </h4>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія зберігає за собою право припинити роботу Вебсайту чи змінити його на свій розсуд у будь-який час. Зокрема, але не виключно, ФОП Ленчинська Анастасія може призупинити доступ до Вебсайту, додати на нього рекламу чи змінити обсяг Послуг чи доступний функціонал. Продовження використання Користувачем Вебсайту після будь-яких змін передбачає згоду Користувача з такими змінами.
        </p>
        <p className='paragraph'>
          Користувачам заборонено використовувати пошукових роботів («павуків») на будь-яких сторінках Вебсайту для збору даних, чи будь-якої частини контенту (вручну чи за допомогою автоматизованих засобів), а також вчиняти дії, що націлені на порушення нормального функціонування нашого Вебсайту, як за допомогою програмного забезпечення, так і без нього.
        </p>
        <p className='paragraph'>
          Користувач зобов'язується не здійснювати жодних незаконних дій з використанням Вебсайту або у його межах.
        </p>
        <p className='paragraph'>
          Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, а також всіма правами і повноваженнями, необхідними і достатніми для укладання Договору і виконання цих Умов.
        </p>
        <p className='paragraph'>
          Сторони звільняються від відповідальності за повне або часткове невиконання умов Договору внаслідок настання обставин непереборної сили (форс-мажорних обставин)
        </p>
        <h4 className='paragraph'>
          9. Застереження щодо відповідальності та обмеження
        </h4>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія не може гарантувати, що Вебсайт безперебійно та безпомилково працюватиме на всіх пристроях та за всіх особливих умов. Проте, ФОП Ленчинська Анастасія докладатиме всіх розумних зусиль, щоб забезпечити надання якісних Послуг.
        </p>
        <p className='paragraph'>
          Вебсайт може містити посилання на інші вебсайти та ресурси, надані третіми сторонами (зокрема, платіжну систему). Ми не несемо жодної відповідальності за будь-який контент вебсайтів та ресурсів третіх сторін, ми також не контролюємо ці вебсайти та ресурси. Ви використовуєте такі вебсайти та ресурси третіх сторін на ваш власний ризик.
        </p>
        <p className='paragraph'>
          В обсязі, дозволеному законом, у жодному випадку ФОП Ленчинська Анастасія, наші працівники, підрядники, партнери чи агенти не несуть відповідальності за будь-які неточності чи упущення та будь-які спеціальні, непрямі та інші збитки, упущену вигоду, що спричинена чи будь-яким чином пов'язана із використанням чи неможливістю використання Вебсайту чи опублікованої на ньому інформації.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія не несе відповідальність за невикористання Користувачем Ресурсів внаслідок обставин, що не залежать від ФОП Ленчинська Анастасія.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія не несе відповідальність за невідповідність наданих Послуг очікуванням Користувача.
        </p>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія не несе відповідальність за наслідки, спричинені несанкціонованим використанням ідентифікаційних даних Користувача третіми особами.
        </p>
        <p className='paragraph'>
          Сторони звільняються від відповідальності за невиконання або неналежне виконання своїх зобов'язань у випадку, якщо таке невиконання або неналежне виконання сталося внаслідок дії обставин непереборної сили. У випадку виникнення обставин непереборної сили Сторона, яка знаходиться під її дією, повідомляє про це іншу Сторону. Факт дії обставин непереборної сили підтверджується сертифікатом, виданим Торгово-промисловою палатою України та уповноваженими нею регіональними торгово-промисловими палатами.
        </p>
        <h4 className='paragraph'>
          10. Зміни
        </h4>
        <p className='paragraph'>
          Ми можемо змінювати, доповнювати чи в інший спосіб модифікувати ці Умови час від часу у випадках, коли це вимагається змінами до законодавства, коли це потрібно для кращого захисту прав Користувачів, ФОП Ленчинською Анастасією чи третіх осіб, коли ми будемо розширювати обсяг Послуг або коли це зумовлено економічною необхідністю чи будь-якими змінами у нашій операційній діяльності.
        </p>
        <p className='paragraph'>
          Якщо ви продовжуєте використовувати Вебсайт після таких змін, ми вважатимемо, що ви прийняли їх, крім випадків, коли ФОП Ленчинська Анастасія має обов'язок відповідно до цих Умов або застосовного закону отримати вашу явну згоду на такі зміни.
        </p>
        <h4 className='paragraph'>
          Застосовне право та вирішення спорів
        </h4>
        <p className='paragraph'>
          Ці Умови та інші взаємовідносини між вами та ФОП Ленчинською Анастасією регулюються правом України.
        </p>
        <p className='paragraph'>
          Усі спори та розбіжності, що можуть виникнути щодо цих Умов, повинні вирішуватися шляхом переговорів. Для цілей вирішення таких спорів, ефективним та обов'язковим методом комунікації є кореспонденція з уповноваженими особами ФОП Ленчинською Анастасією на адресу me@anastasija-ok.com
        </p>
        <p className='paragraph'>
          Якщо спір не може бути вирішеним шляхом переговорів протягом 60 днів, він повинен бути переданий та остаточно вирішений відповідним судом за правом України.
        </p>
        <h4 className='paragraph'>
          12. Припинення дії
        </h4>
        <p className='paragraph'>
          Умови Публічної оферти в частині електронного договору про надання Послуг припиняють свою дію у зв'язку з належним наданням Послуг або за ініціативою Сторін, як зазначено у відповідних пунктах Публічної оферти.
        </p>
        <p className='paragraph'>
          Умови в частині, яка не пов'язана із наданням Послуг (умови про інтелектуальну власність, технічну підтримку та персональні дані), діють безстроково.
          </p>
        <h4 className='paragraph'>
          Реквізити Виконавця
        </h4>
        <p className='paragraph'>
          ФОП Ленчинська Анастасія
        </p>
        <p className='paragraph'>
          ІПН: 2885301803
        </p>
        <p className='paragraph'>
          Реєстр.№: 25960170000001634 від 10.09.2002 
        </p>
        <p className='paragraph'>
          IBAN: UA413052990000026000040702800
        </p>
        <p className='paragraph'>
          Платник єдиного податку 3 група, ставка 5%. Не є платником ПДВ
        </p>
        <p className='paragraph'>
          Ел. пошта: me@anastasija-ok.com
        </p>
      </div>
    </>
  )
}
