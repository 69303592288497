import styles from './styles.module.css'
import Img1 from '../../assets/advertisements/1.png'
import Img2 from '../../assets/advertisements/2.png'
import Img3 from '../../assets/advertisements/3.png'
import Img4 from '../../assets/advertisements/4.png'
import Img5 from '../../assets/advertisements/5.png'
import Img6 from '../../assets/advertisements/6.png'
import Img7 from '../../assets/advertisements/7.png'

export const ActualitySection = ({isMobile}) => {
  
  return (
    <div className={isMobile ? styles.actualitySectionMob : styles.actualitySection} id='actuality'>
      <div className={isMobile ? styles.zeroMob : styles.zero}>
        {isMobile && (
          <div className='flex flex-col'>
            <p className={styles.titleMob}>Чому цей курс <span className={styles.blue}>актуальний?</span></p>
            <p className={styles.subtitleMob}>Думаєш що не знайдеш себе в IT сфері ? Що немає вільних достойних вакансій ? А якщо ми доведемо тобі, що це не так ? Ось декілька із них :</p>
            <p className={styles.subtitleMob}>А на курсі ти дізнається де шукати ці круті пропозиції та як туди потрапити.</p>
          </div>
        )}
        <div className={styles.mobile}>
          <span className={isMobile ? styles.border1Mob : styles.border1}><img src={Img1} className={styles.image1} alt='img-1' /></span>
          <span className={isMobile ? styles.border2Mob : styles.border2}><img src={Img2} className={styles.image1} alt='img-2' /></span>
        </div>
        {!isMobile && (
          <div className={styles.rightText}>
            <p className={styles.title}>Чому цей курс <span className={styles.blue}>актуальний?</span></p>
            <p className={styles.subtitle}>Думаєш що не знайдеш себе в IT сфері ? Що немає вільних достойних вакансій ? А якщо ми доведемо тобі, що це не так ? Ось декілька із них :</p>
            <p className={styles.subtitle}>А на курсі ти дізнається де шукати ці круті пропозиції та як туди потрапити.</p>
          </div>
        )}
      </div>
      <div className={isMobile ? styles.firstGroupMob : styles.firstGroup}>   
        <span className={styles.border3}><img src={Img3} className={styles.image3} alt='img-3' /></span>
        <div className={isMobile ? styles.mobileDirectMob : styles.mobileDirect}>
          <span className={isMobile ? styles.border1Mob : styles.border1 }><img src={Img4} className={styles.image1} alt='img-4' /></span>
          <span className={isMobile ? styles.border2Mob : styles.border2 }><img src={Img5} className={styles.image1} alt='img-5' /></span>
        </div>
      </div>
      <div className={styles.secondGroup}>
        <span className={isMobile ? styles.border1Mob : styles.border1 }><img src={Img6} className={styles.image1} alt='img-3' /></span>
        <span className={isMobile ? styles.border2Mob : styles.border2 }><img src={Img7} className={styles.image1} alt='img-4' /></span>
      </div>
    </div>
  )
}
