import { useEffect, useState } from 'react';
import styles from './styles.module.css'
import moment from 'moment';


export const CtaSection = ({isMobile}) => {
  const targetDate = moment('2023-07-27'); 
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());


  function calculateRemainingTime() {
    const now = moment();
    const diff = targetDate.diff(now);
    const duration = moment.duration(diff);
    return {
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className={isMobile ? styles.containerMob : styles.container}>
      <p className={styles.freeLabel}>Безкоштовно</p>
      <div className={styles.titleContainer}>
        <p className={isMobile ? styles.titleMob : styles.title}>Встигни отримати бонусну консультацію, якщо зареєструєшся прямо зараз</p>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.timer}>
        {remainingTime.days === 0 && remainingTime.hours === 0 && remainingTime.minutes === 0 && remainingTime.seconds === 0 ? (
          <p>Time's up!</p>
        ) : (
          <p className={styles.clock}>
            {remainingTime.days} : {remainingTime.hours} : {remainingTime.minutes} : {remainingTime.seconds}
          </p>
        )}
        </div>
      </div>
      <div className={styles.titleContainer}>
        <a href='#contacts' className={styles.connectButtonSecond}>Приєднатися</a>
      </div>
    </div>
  )
}
