import styles from './styles.module.css'

export const Questions = ({isMobile}) => {
  return (
    <div className={isMobile ? styles.containerMob : styles.container}>
      <p className={styles.title}>Часті запитання</p>
      <div className={styles.accordion} id='questionsLink'>
        <span className={styles.targetFix} id="accordion"></span>
        <hr className={styles.hr} />
        <div className={styles.accordeons}>
          <div>
            <span className={styles.targetFix} id="accordion1"/>
            <a href="#accordion1" id="openAccordion1" className="open" title="open">Де та як проходить курс?</a>
            <a href="#accordion" id="closeAccordion1" title="close">Де та як проходить курс?</a>
            <div className={styles.accordionContent}>
              <p className={styles.answer}>Онлайн у google meet . Заняття проходять по суботах з 12:00 до 14:00 за київським часом і тривають 2 години . Буде 10 занять, 10 годин  практичних вправ і 10 голин інтенсивного вивчення матеріалу та невеликі домашні завдання  .
Учасники спілкуються з лектором та організатором і  між собою у телеграм-чатах. Ми запросимо вас до групи за декілька днів до початку.</p>
            </div>
          </div>

          <hr className={styles.hr} />
          <div>
            <span className={styles.targetFix} id="accordion2"/>
            <a href="#accordion2" id="openAccordion2" className="open" title="open">Чи буде запис курсу?</a>
            <a href="#accordion" id="closeAccordion2" title="close">Чи буде запис курсу?</a>
            <div className={styles.accordionContent}>
              <p className={styles.answer}>Так, додатково всі учасники отримають багато  додаткових матеріалів для подальшого розвитку.</p>
            </div>
          </div>

          <hr className={styles.hr} />
          <div>
            <span className={styles.targetFix} id="accordion3"/>
            <a href="#accordion3" id="openAccordion3" className="open" title="open">Чи можна оплатити курс частинами?</a>
            <a href="#accordion" id="closeAccordion3" title="close">Чи можна оплатити курс частинами?</a>
            <div className={styles.accordionContent}>
              <p className={styles.answer}>Так. Будь ласка, напишіть нам у телеграм, месенджер або інстаграм — відповімо швидко та по ділу.</p>
            </div>
          </div>

          <hr className={styles.hr} />
          <div>
            <span className={styles.targetFix} id="accordion4"/>
            <a href="#accordion4" id="openAccordion4" className="open" title="open">Не виходить оплатити курс. Що робити?</a>
            <a href="#accordion" id="closeAccordion4" title="close">Не виходить оплатити курс. Що робити?</a>
            <div className={styles.accordionContent}>
              <p className={styles.answer}>Не страшно, в інтернеті завжди щось десь не працює :) Але ми завжди зможемо вам допомогти! Якщо щось або хтось стоїть між вами та курсом, напишіть нам у телеграм, месенджер або інстаграм .</p>
            </div>
          </div>

          <hr className={styles.hr} />
          <div>
            <span className={styles.targetFix} id="accordion5"/>
            <a href="#accordion5" id="openAccordion5" className="open" title="open">Чи участь в цьому курсі наближає Україну до перемоги?</a>
            <a href="#accordion" id="closeAccordion5" title="close">Чи участь в цьому курсі наближає Україну до перемоги?</a>
            <div className={styles.accordionContent}>
              <p className={styles.lAnswer}>Так.  Ми передамо 10% прибутку від курсу на благодійні ініціативи, які наближають нашу перемогу.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
