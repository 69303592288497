import styles from './styles.module.css'
import Logo from '../../assets/svg/Logo.svg'

export const Header = ({isMobile}) => {
  return (
    <div className={styles.header}>
      <a href='/'>
        <img src={Logo} alt='Logo' className={isMobile ? styles.headerLogoMob : styles.headerLogo}/>
      </a>
      <nav className={isMobile ? styles.headerNavMob : styles.headerNav}>
        <ul className={styles.headerList}>
        {!isMobile && (
          <>
            <li className={styles.headerItem}><a href='#aboutRoute' className={styles.link}>Про курс</a></li>
            <li className={styles.headerItem}><a href='#team' className={styles.link}>Команда</a></li>
            <li className={styles.headerItem}><a href='#price' className={styles.link}>Вартість</a></li>
            <li className={styles.headerItem}><a href='#questionsLink' className={styles.link}>Часті запитання</a></li>
            <li className={styles.headerItem}><a href='#contacts' className={styles.link}>Контакти</a></li>
          </>
        )}  
        <li className={styles.headerItem}><a href='#contacts' className={styles.registerCourceBtn}>Записатися</a></li>
        </ul>
      </nav>
    </div>
  )
}
