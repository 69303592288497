import styles from './styles.module.css'
import Teacher1 from '../../assets/teacher1.png'
import Teacher2 from '../../assets/teacher2.png'
import clsx from 'clsx'

export const TeamSection = ({isMobile}) => {
  return (
    <div className={isMobile ? styles.teamSectionMob : styles.teamSection} id='team'>
      <p className={styles.title}>Команда</p>
      <p className={styles.subtitle}>що постійно з вами на звʼязку</p>
      <div className={isMobile ? styles.teamBlockMob : styles.teamBlock}>
        <div className={isMobile ? styles.teacherBlockMob : styles.teacherBlock}>
          <p className={styles.teacher}>Анастасія Лечинська</p>
          <ul className={isMobile ? styles.tipsMob : styles.tips}>
            <li className={styles.tip}>10+ років в IT</li>
            <li className={styles.tip}>5 років психотерапевтичної практики</li>
            <li className={styles.tip}>Керівник відділу HR</li>
            <li className={styles.tip}>Гештальт - психотерапевт</li>
          </ul>
          <img src={Teacher1} className={isMobile ? styles.teacherImageMob : styles.teacherImageFirst} alt='Teacher 1' />
        </div>
        <div className={isMobile ? styles.teacherBlockMob : styles.teacherBlock}>
          <p className={styles.teacher}>Анастасія Омельченко</p>
          <ul className={clsx(isMobile ? styles.tipsMob : styles.tips, styles.mb)}>
            <li className={styles.tip}>Анастасія Омельченко</li>
            <li className={styles.tip}>Студентка та майбутній психолог</li>
            <li className={styles.tip}>Координатор проектів</li>
          </ul>
          <img src={Teacher2} className={isMobile ? styles.teacherImageMob : styles.teacherImageSecond} alt='Teacher 2' />
        </div>
      </div>
    </div>
  )
}
