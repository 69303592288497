import './App.css';
import React, { useState, useEffect } from 'react'
import { ActualitySection } from './components/actuality';
import { CtaSection } from './components/cta';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { AboutSection } from './components/about';
import { PriceSection } from './components/priceSection';
import { ProgramSection } from './components/program';
import { Questions } from './components/questions';
import { TeamSection } from './components/team';
import { InfoSection } from './components/infoSection';
import { Offerta } from './components/offerta';
import { Terms } from './components/terms';
import { SnackbarProvider} from 'notistack'

const MODAL = {
  offerta: '#offerta', 
  terms: '#terms'
}
function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [showModal, setShowModal] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      setShowModal(hash);
    };
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  console.log('showModal ', showModal)
  // console.log('Window location hash', window.location.hash)

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div className="App">
        <Header isMobile={isMobile}/>
        <InfoSection isMobile={isMobile} />
        <AboutSection isMobile={isMobile} />
        <ProgramSection isMobile={isMobile}/>
        <CtaSection isMobile={isMobile}/>
        <ActualitySection isMobile={isMobile} />
        <TeamSection isMobile={isMobile} />
        <Questions isMobile={isMobile} />
        <PriceSection isMobile={isMobile} />
        <Footer isMobile={isMobile} />
        {showModal === MODAL.offerta && (
          <Offerta setShowModal={setShowModal}/>
        )}
        {showModal === MODAL.terms && (
          <Terms setShowModal={setShowModal}/>
        )}
      </div>
    </SnackbarProvider>
  );
}

export default App;
