import styles from './styles.module.css'
import Done from '../../assets/svg/Done Icon.svg'
import clsx from 'clsx'

const ABOUT = [
  {
    title: 'Ви зрозумієте:',
    description: 'Що таке IT. Структура компаніі, позиціі і ролі, які ви зможете зайняти в цій системі.'
  }, {
    title: 'Ви зможете:',
    description: 'Впевнено скласти резюме і зрозуміти, які вакансії для вас підходять. Де і як шукати роботу.'
  }, {
    title: 'Ви отримаєте:',
    description: 'Практичні знання, як будувати свою карʼєру, корисний матеріал.'
  }
]

export const AboutSection = ({isMobile}) => {
  const courseBlock = (el, key) => {
    return (
      <div key={key} className={isMobile ? styles.courseBlockMob : styles.courseBlock} id='aboutRoute'>
        <img src={Done} alt='Logo' className={styles.doneLogo}/>
        <h4 className={styles.titleCourse}>{el.title}</h4>
        <p className={clsx('detailFont', styles.descSection)}>{el.description}</p>
      </div>
    )
  }

  return (
    <div className={styles.aboutCourse}>
      <h2> Про що курс?</h2>
      <div className={styles.course}>
        <div className={isMobile ? styles.courseElementsMob : styles.courseElements}>
          {ABOUT.map((el, key) => courseBlock(el, key))}
        </div>
      </div>
    </div>
  )
}
